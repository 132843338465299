<i18n>
{
  "en": {
    "purchase_button": "UPGRADE",
    "col_plan_name": "Plan",
    "col_plan_price": "Price",
    "col_plan_apply": " "
  },
  "ja": {
    "purchase_button": "アップグレード",
    "col_plan_name": "プラン名",
    "col_plan_price": "プラン料金",
    "col_plan_apply": "お申し込み"
  }
}
</i18n>

<template>
<div class="plan-compact">
  <div class="plan-compact-inner ug-plan">
    <div class="plan-section plan-section--title">
      <div>{{ $t('col_plan_name') }}</div>
      <div>{{ $t('col_plan_price') }}</div>
      <div>{{ $t('col_plan_apply') }}</div>
    </div>
    <div class="plan-section" v-for="pkg in orderedPackages" :key="pkg.package_id">
      <!-- plan name + status -->
      <div class="plan-name">
        {{ pkg.tpl.description[locale] }}
        <span class="plan-note" @click="secretlyShowPackageIDs()">{{ pkg.tpl.note.status[locale] }}</span>
        <span class="plan-note" v-if="PID.isVisible">{{ pkg.package_id }}</span>
        <!-- debug -->
        <span class="plan-note" v-if="PID.clickCount > 20 && PID.clickCount % 3 === 0">
          userClass: {{ userClass }}<br />
          userTier: {{ userTier }}<br /><br />

          packageType: {{ packageType }}<br /><br />

          class_vis.Streaming: {{ pkg.user_class_visibility.Streaming }}<br />
          class_vis.Download: {{ pkg.user_class_visibility.Download }}<br /><br />

          tier_vis.Member: {{ pkg.user_tier_visibility.Member }}<br />
          tier_vis.VIP: {{ pkg.user_tier_visibility.VIP }}<br />
          tier_vis.SVIP: {{ pkg.user_tier_visibility.SVIP }}<br />
          tier_vis.Annual: {{ pkg.user_tier_visibility.Annual }}<br /><br />

          is-disabled-cond1: {{ !pkg.user_tier_visibility[userTier] }}<br />
          is-disabled-cond2: {{ (userClass === 'Download' && pkg.user_class_visibility.Download && pkg.user_tier_visibility[userTier]) }}<br />
          is-disabled: {{ isPackageDisabled(pkg) }}<br />
        </span>
      </div>
      <!-- original price, or if campaign price is the same as the original price -->
      <div class="plan-price" v-if="!pkg.tpl.price_original || (pkg.tpl.price_original && pkg.tpl.price_original === pkg.tpl.price)">
        {{ pkg.tpl.currency_symbol.prefix }}{{ pkg.tpl.price }}{{ pkg.tpl.currency_symbol.suffix }}
      </div>
      <!-- if campaign price is different than original, show crossed-out original price and campaign price -->
      <div class="plan-price" v-if="pkg.tpl.price_original && pkg.tpl.price_original !== pkg.tpl.price">
        <strike>{{ pkg.tpl.currency_symbol.prefix }}{{ pkg.tpl.price_original }}{{ pkg.tpl.currency_symbol.suffix }}</strike>
        <span class="plan-price discount">{{ pkg.tpl.currency_symbol.prefix }}{{ pkg.tpl.price }}{{ pkg.tpl.currency_symbol.suffix }}</span>
      </div>
      <div class="plan-cta"><button class="button-fill button-default button-medium" :class="{'is-disabled': isPackageDisabled(pkg)}" @click="clickPurchaseButton(pkg)">{{ $t('purchase_button') }}</button></div>
    </div>
  </div>
</div>
</template>

<script>
/* eslint no-console: 0 */
/* eslint max-len: 0 */

import UAParser from 'ua-parser-js';
import orderBy from 'lodash/orderBy';
import keyBy from 'lodash/keyBy';
import BifrostAPI from '@/assets/js/services/Bifrost/API';
import Analytics from '@/assets/js/utils/Analytics';

export default {
  props: {
    currency: {
      type: String,
      default: 'USD',
    },
    packageType: {
      type: String,
      required: true,
    },
    allowAllForActivePackage: {
      type: Array,
      required: false,
    },
    disallowAllForActivePackage: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      packageList: [],
      packageMap: {},
      signerUrls: {},
      deviceType: 'desktop',

      // show package ID info secretly 🤫
      PID: {
        isVisible: false,
        clickCount: 0,
      },
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    orderedPackages() {
      return orderBy(this.packageList, 'tpl.display_order');
    },
    userType() {
      return this.$store.getters['user/typeStr'];
    },
    userClass() {
      return this.$store.getters['user/class'];
    },
    userTier() {
      return this.$store.getters['user/tier'];
    },
    userBilling() {
      return this.$store.getters['user/billing'];
    },
    isAnnual() {
      return this.$store.getters['user/isAnnual'];
    },
  },
  created() {
    this.getPackagesAndUrls();
  },
  methods: {
    async getPackagesAndUrls() {
      // get device type
      const parser = new UAParser();
      const device = parser.getDevice();
      // UAParser returns separate 'mobile' and 'tablet' device types (and a few others). Fixes a
      // bug we had where packages, were not appearing for iPads since those were returning 'tablet'
      // device types here, but the package list only designates 'mobile' types or 'desktop'. I
      // doubt Sales will be requesting specific packages for 'tablet' (unless they want to track
      // those separately) so we're just folding all devices as 'mobile' here:
      this.deviceType = device.type ? 'mobile' : 'desktop';

      // fetch join and campaign package lists
      const bfAPI = new BifrostAPI();
      const packageList = await bfAPI.getJoinPackages();
      const campaignPackageList = await bfAPI.getCampaignPackages();

      // walk thru campaign packages and override corresponding package in this.packageList
      if (
        Object.prototype.hasOwnProperty.call(campaignPackageList, this.currency)
      ) {
        for (
          let cpl = 0;
          cpl < campaignPackageList[this.currency].length;
          cpl += 1
        ) {
          for (let pl = 0; pl < packageList[this.currency].length; pl += 1) {
            if (
              packageList[this.currency][pl].label
                === campaignPackageList[this.currency][cpl].label
            ) {
              // save original price
              const originalPrice = packageList[this.currency][pl].tpl.price;

              // override original package with campaign package
              packageList[this.currency][pl] = campaignPackageList[this.currency][cpl];

              // restore original price
              packageList[this.currency][pl].tpl.price_original = originalPrice;
              break;
            }
          }
        }
      }

      // key by package_id for duration+price lookups for disabling the package item
      const packageListCopy = [...packageList[this.currency]]; // need to do a copy because the of filter below
      this.packageMap = keyBy(packageListCopy, 'package_id');

      // get packages to display for this user/page/locale/etc
      // instead of filtering out all ineligible upgrade packages, we show them all on the table,
      // but disable (grey out) the package on the template side if ineligible
      this.packageList = packageList[this.currency].filter(
        item => item.page_visibility[this.packageType]
          && item.user_class_visibility[this.userClass]
          && item.device_visibility[this.deviceType]
          && item.locale_visibility[this.locale],
      );

      // first build signerUrls object using fallback URLs (in case we get nothing from Signer)
      const fallbackUrlsArr = this.packageList.map((item) => {
        const obj = {};
        obj[item.label] = item.fallback_url;
        return obj;
      });
      const fallbackUrls = Object.assign({}, ...fallbackUrlsArr);
      this.signerUrls = fallbackUrls;

      // now get signer urls
      this.$analytics.getClientId().then((clientId) => {
        bfAPI.getSignerURLs(clientId).then((response) => {
          if (response.status === 200) {
            if (Object.prototype.hasOwnProperty.call(response.data.data, 'join')) {
              // overwrite fallback urls with signer urls
              Object.keys(this.signerUrls).forEach((label) => {
                if (Object.prototype.hasOwnProperty.call(response.data.data.join, label)) {
                  this.$set(this.signerUrls, label, response.data.data.join[label]);
                }
              });
              if (Object.prototype.hasOwnProperty.call(response.data.data, 'campaign')) {
                // overwrite signerUrl urls with campaign shooter urls
                Object.keys(response.data.data.campaign).forEach((label) => {
                  this.$set(this.signerUrls, label, response.data.data.campaign[label]);
                });
              }
            }
          }
        });
      });
    },
    isPackageDisabled(pkg) {
      let isDisabled = false;

      let activePackageID = 0;
      let activePackageDuration = 0;
      let activePackagePrice = 0;

      if (this.userBilling) {
        activePackageID = this.userBilling.PackageID;
        activePackageDuration = this.userBilling.PackageDuration;
        activePackagePrice = 0;

        // we don't get this from the billing API. so we need to do a lookup from our table
        if (Object.prototype.hasOwnProperty.call(this.packageMap, activePackageID)) {
          activePackagePrice = Number(this.packageMap[activePackageID].tpl.price);
        }
      }

      // allowAllForActivePackage override takes precedence
      if (this.allowAllForActivePackage && this.allowAllForActivePackage.includes(activePackageID)) {
        isDisabled = false;
      } else {
        isDisabled = (
          // disabled if it's set to not be visible for this user tier
          !pkg.user_tier_visibility[this.userTier]
          // if it's NOT a (higher or equal duration) AND higher price, then the pkg is disabled
          || !(pkg.duration >= activePackageDuration && Number(pkg.tpl.price) > activePackagePrice)
          // if disallowAllForActivePackage override is set for the user's active package id
          || (this.disallowAllForActivePackage && this.disallowAllForActivePackage.includes(activePackageID))
        );
      }

      return isDisabled;
    },
    clickPurchaseButton(pkg) {
      // note: we can't do any calls in this function that return promises in this function.
      // otherwise browsers will likely block the new window from opening, thinking it's a popup.
      // browsers will open 'window.open()' urls if it directly comes from a user click event
      this.$analytics.trackEvent('UpgradeClick', pkg.ga.action, pkg.ga.label);

      // send custom GA4 event (per: http://redmine.dl/issues/3383)
      const durationNameMap = {
        30: '30 days',
        90: '90 days',
        180: '180 days',
        360: '360 days',
      };
      Analytics.sendCustomEvent('upgrade_click', {
        plan_type: 'DL Plan',
        plan_detail: (durationNameMap[pkg.duration]) ? durationNameMap[pkg.duration] : pkg.duration,
      });

      let purchaseUrl = this.signerUrls[pkg.label];

      // append language param to the purchase URL
      purchaseUrl += pkg.label.includes('-en')
        ? '&lang=en'
        : '&lang=jp';

      window.open(purchaseUrl, '_blank');
    },
    secretlyShowPackageIDs() {
      if (this.PID.clickCount > 6) this.PID.isVisible = true;
      this.PID.clickCount += 1;
    },
  },
  watch: {
    // refresh table when user type (or login state) changes
    userType(newVal, oldVal) {
      if (newVal !== oldVal) this.getPackagesAndUrls();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
